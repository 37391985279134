<template>
    <div class="page">
        <el-form size="small" :inline="true" class="query-form" ref="searchForm" :model="searchForm" @keyup.enter.native="refreshList()" @submit.native.prevent>
            <el-form-item prop="title">
                <el-input size="small" v-model="searchForm.title" placeholder="请输入任务名称" clearable></el-input>
            </el-form-item>

            <el-form-item prop="searchDates">
                <el-date-picker
                        v-model="searchForm.searchDates"
                        type="daterange"
                        size="small"
                        align="right"
                        value-format="yyyy-MM-dd"
                        unlink-panels
                        clearable
                        range-separator="至"
                        start-placeholder="创建开始时间"
                        end-placeholder="创建结束时间">
                </el-date-picker>
            </el-form-item>

            <el-form-item prop="triggerState">
                <el-select v-model="searchForm.triggerState" clearable placeholder="请选择任务运行情况">
                    <el-option label="开启" value="0"></el-option>
                    <el-option label="关闭" value="1"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" @click="refreshList()" size="small" icon="el-icon-search">查询</el-button>
                <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
            </el-form-item>
            <el-form-item style="float:right">
                <el-button icon="el-icon-plus"
                           v-show="hasPermission('admin:task:add')"
                           @click="view('add', null)" size="small" type="primary">新建</el-button>
            </el-form-item>
        </el-form>
        <div class="bg-white top">
            <el-table
                    :data="dataList"
                    v-loading="loading"
                    size="small"
                    height="calc(100% - 80px)"
                    @selection-change="selectionChangeHandle"
                    class="table">
                <el-table-column
                        prop=""
                        show-overflow-tooltip sortable
                        label="序号">
                        <template slot-scope="scope">{{scope.$index+1}}</template>
                </el-table-column>
                <el-table-column
                        prop="jobName"
                        show-overflow-tooltip sortable
                        label="任务名称">
                </el-table-column>
                <el-table-column
                        prop="description"
                        show-overflow-tooltip sortable
                        label="任务描述">
                </el-table-column>
                <el-table-column
                        prop="cronExpression"
                        show-overflow-tooltip sortable
                        label="执行计划">
                </el-table-column>
                <el-table-column
                        prop="jobClassName"
                        show-overflow-tooltip sortable
                        label="执行类">
                </el-table-column>
                <el-table-column
                        prop="triggerState"
                        show-overflow-tooltip sortable
                        label="运行情况">
                        <template slot-scope="scope">{{scope.row.triggerState == 0 ? '开启' : '关闭'}}</template>
                </el-table-column>
                <el-table-column
                        prop="createTime"
                        show-overflow-tooltip sortable
                        label="创建时间">
                </el-table-column>
                <el-table-column
                        prop="createUserName"
                        show-overflow-tooltip
                        label="创建人">
                </el-table-column>
                <el-table-column
                        fixed="right"
                        width="200"
                        label="操作">
                    <template slot-scope="scope">
                        <el-button size="mini" type="primary" v-show="hasPermission('admin:task:start')"
                                   plain @click="suspendTask(scope.row)">
                            {{scope.row.triggerState == 1 ? '开启' : '关闭'}}
                        </el-button>

                        <el-button size="mini" v-show="hasPermission('admin:task:edit')"
                                   type="primary" plain @click="view('edit', scope.row)">编辑</el-button>
                        <el-button size="mini" v-show="hasPermission('admin:task:del')"
                                   type="danger" plain @click="del(scope.row.id,scope.row.jobName)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <!--新建、编辑-->
        <AddMetadataForm ref="addRecordForm" @refreshDataList="refreshList"></AddMetadataForm>
    </div>
</template>

<script>
    import AddMetadataForm from './acquisitonTaskFrom' // 新建编辑弹窗
    export default {
        components: {AddMetadataForm},
        data() {
            return {
                searchForm: {
                    title: "",
                    searchDates: [],
                    startTime: '',
                    endTime: '',
                    triggerState:''
                },
                sceneOpt: [
                    {
                        label: '全部',
                    },
                    {
                        label: '我上传的文件',
                    }
                ],
                dataList: [
                    {}
                ],
                pageNo: 0,
                pageSize: 10,
                total: 0,
                loading: false,
                dataListSelections: [],
                drawerShow: false,
                moduleId: 34,   // 当前请求拖拽排序数据id
                setShow: false, // 是否显示列表设置拖拽排序弹窗
            }
        },
        activated() {
            this.refreshList()
        },
        watch: {
            searchDates() {
                if (this.searchDates) {
                    this.searchForm.beginDate = this.searchDates[0]
                    this.searchForm.endDate = this.searchDates[1]
                } else {
                    this.searchForm.beginDate = ''
                    this.searchForm.endDate = ''
                }
            }
        },
        methods: {
            // 新建、编辑
            view(method, row) {
                this.$refs.addRecordForm.init(method, row)
            },
            // 获取数据列表
            refreshList() {
                // this.loading = true
                this.$axios(this.api.rcPage.taskList, {
                    'current': this.pageNo,
                    'size': this.pageSize,
                    'type': this.searchForm.type,
                    'jobName': this.searchForm.title,
                    'startTime': this.searchForm.searchDates[0],
                    'endTime': this.searchForm.searchDates[1],
                    'triggerState':this.searchForm.triggerState
                }, 'get').then(data => {
                    this.dataList = data.data.records
                    this.total = parseInt(data.data.total);
                    this.loading = false

                })
            },
            // 查询目录
            getFileList() {
                this.loading = true
                this.$axios(this.api.common.culturalRelicsList, {}, 'get').then(data => {
                    if (data && data.status) {
                    }
                })
            },
            // 删除
            del(id,jobName) {
                let ids = id || this.dataListSelections.map(item => {
                    return item.id
                })
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.rcPage.taskRemoveByIds, [{'id': ids,'jobName':jobName}], 'post').then(data => {
                        if (data && data.status) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1500
                            })
                            this.refreshList()
                            this.visible = false
                        }
                    })
                })
            },
            // 开启任务或者关闭
            suspendTask(row) {
                if(row.triggerState == 1){
                    row.triggerState = 0
                }else{
                    row.triggerState = 1
                }
                this.$axios(this.api.rcPage.taskSuspendTask, row, 'post').then(data => {
                    if (data && data.status) {
                        if (data && data.status) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1500
                            })
                        this.visible = false
                    }
                        this.refreshList()
                    }
                })
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageNo = 1
                this.refreshList()

            },
            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val
                this.refreshList()
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            resetSearch() {
                this.searchDates = []
                this.$refs.searchForm.resetFields()
                this.$nextTick(() => {
                    this.refreshList()
                })
            }
        }
    }
</script>
